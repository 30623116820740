import { gql } from 'src/generated/gql';

export const LIST_PATIENT_SUBMITTED_LAB_RESULTS_QUERY = gql(`
  query ListPatientSubmittedLabResults($patientId: ID!) {
    staff_listLabResults(patientId: $patientId) {
      result {
        bupPositive
        createdAt
        id
        notes
        patientId
        signedAt
        signedBy {
          firstName
          lastName
        }
        status
        updatedAt
        verified
        status
        signedBy {
          id
        }
        signedAt
        invalidReason
      }
      images {
        url
      }
    }
  }
`);
