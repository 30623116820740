/* eslint-disable camelcase */
import { makeStyles } from '@material-ui/styles';
import { format, isValid, parseISO } from 'date-fns';
import { Field, Formik } from 'formik';
import React from 'react';

import { PrimaryButton } from 'src/components/general/PrimaryButton';
import { SecondaryButton } from 'src/components/general/SecondaryButton';
import { Staff_LabResultStatus } from 'src/generated/gql/graphql';
import { PatientSubmittedTestResult } from 'src/labs/types';
import { BooleanControl } from 'src/nightingale/components/ChartProperty/controls/Boolean/BooleanControl';
import { TextControl } from 'src/nightingale/components/ChartProperty/controls/Text/TextControl';

export const EditLabResultFormV2: React.FC<{
  images: PatientSubmittedTestResult['images'];
  item: Partial<PatientSubmittedTestResult['result']>;
  onCancel: () => void;
  onSave: (values: Partial<PatientSubmittedTestResult['result']>) => Promise<void>;
}> = ({ images, item, onCancel, onSave }) => {
  const classes = useStyles();
  const isEditable = item.status !== Staff_LabResultStatus.Resulted;

  return (
    <>
      <figure className={classes.images}>
        {images.map((image, index) => (
          <img
            className={classes.resultImage}
            src={image.url}
            alt={`Lab result upload #${index + 1}`}
          />
        ))}
      </figure>

      <Formik
        initialValues={item}
        onSubmit={(values, actions) => {
          const filteredValues = {
            ...values,
            bupPositive: values.verified ? values.bupPositive : null,
            // We don't pass these down in the mutation
            status: undefined,
            signedAt: undefined,
            signedBy: undefined,
          };
          onSave(filteredValues).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {({ handleSubmit, isSubmitting, dirty, values }) => {
          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Field
                label="Result valid?"
                name="verified"
                isEditable={isEditable}
                component={FormBooleanControl}
              />
              {values.verified ? (
                <Field
                  label="Bup positive?"
                  name="bupPositive"
                  isEditable={isEditable}
                  component={FormBooleanControl}
                />
              ) : null}
              <Field label="Additional Notes" name="notes" multiline component={FormTextControl} />
              {!isEditable && (
                <span className={classes.resultedByText}>{buildResultedByText(item)}</span>
              )}

              <span className={classes.buttons}>
                <div>
                  <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>
                  <PrimaryButton
                    className={classes.button}
                    disabled={isSubmitting || !dirty}
                    type="submit"
                  >
                    Save
                  </PrimaryButton>
                </div>
              </span>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const FormBooleanControl = ({ field, form, label, isEditable }) => (
  <BooleanControl
    label={label}
    name={field.name}
    onChangeValue={newVal => {
      form.setFieldValue(field.name, newVal);
    }}
    value={field.value}
    disabled={!isEditable}
  />
);

const FormTextControl = ({ field, form, label }) => (
  <TextControl
    label={label}
    multiline
    name={field.name}
    onChangeValue={newVal => {
      form.setFieldValue(field.name, newVal);
    }}
    value={field.value || ''}
  />
);

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    '& .MuiFormControl-root': {
      marginBottom: 16,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 20,
  },
  button: {
    marginLeft: 8,
  },
  images: {
    alignItems: 'center',
    columnGap: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 0 20px',
    rowGap: 20,
  },
  resultImage: {
    display: 'block',
    height: 'auto',
    margin: 0,
    marginBottom: '20px',
    maxWidth: '90vh',
    width: '100%',
  },
  resultedByText: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 13,
    fontStyle: 'italic',
  },
});

/**
 * Builds a string of the form "Resulted by {firstName} {lastName} on {date/time}", but leaving
 * out any data this is not populated on the input `item`.
 */
function buildResultedByText(item: Partial<PatientSubmittedTestResult['result']>): string {
  let text = 'Resulted';

  if (item.signedBy?.firstName || item.signedBy?.lastName) {
    text += ` by ${item.signedBy?.firstName || '(first name unknown)'} ${
      item.signedBy?.lastName || '(last name unknown)'
    }`;
  }

  if (item.signedAt) {
    const signedAt = parseISO(item.signedAt);
    if (isValid(signedAt)) {
      text += ` on ${format(signedAt, 'PPPppp')}`;
    }
  }

  return text;
}
